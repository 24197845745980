import React from 'react';
import { css } from '@emotion/react';
import WithLayout from '../WithLayout';
import { theme } from '../../domain/theme';
import { t } from '../../domain/services/configService';
import { NotFound } from '../../components/NotFound';

const styles = {
  title: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('xl4')};
    letter-spacing: ${theme.spacing('xs2')};
    margin-bottom: ${theme.spacing('base')};
    color: #fff;
    text-transform: uppercase;
  `,

  noResults: css`
    ${theme.font.family('regular')};
    color: #fff;
    font-size: ${theme.font.size('base')};
    padding: 0 ${theme.spacing('base')};
  `
};

const Error404 = () => (
  <NotFound>
    <h3 css={styles.title}>{t('OOPS!')}</h3>
    <p css={styles.noResults}>
      {t('The page you have requested could not be found.')}
    </p>
  </NotFound>
);

export default WithLayout(Error404);
